import styled from 'styled-components';
import theme from '../../utils/theme';
import params from '../../utils/params';

export const ButtonLink = styled.a`
  text-align: center;
  font-size: 16px;
  font-weight: 500px;
  line-height: 19px;
  display: inline-block;
  width: 144px;
  color: ${theme.textWhite};
  background: ${theme.primaryLight};
  text-decoration: none;
  border-radius: 20px;
  padding: 8px 16px 13px;

  :hover {
    background: ${theme.primaryHover};
    color: ${theme.textWhite};
  }

  :active {
    background: ${theme.primaryDark};
    color: ${theme.textWhite};
  }
`;

export const Content = styled.div`
  max-width: 1600px;

  @media (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
    align-items: flex-start;
  }

  margin: 0 auto;
`;

export const Section = styled.section`
  width:100%;
  padding 0 50px 40px;
    @media (max-width: 900px) {
  padding: 0 30px 40px;
  }
      @media (max-width: 399px) {
  padding: 0 20px 40px;
  } 
  margin: 0 auto;
`;
