import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../Components/layout';
import Hero from '../Components/hero';
import styled from 'styled-components';
import theme from '../utils/theme';
import params from '../utils/params';
import MapImage from '../../static/img/map_2.svg';

const ContactsBlock = styled.div`
  sub {
    font-size: 80%;
  }
  a {
    text-decoration: none;
    white-space: nowrap;
    color: ${theme.primaryLight};
    :hover {
      color: ${theme.primaryHover};
    }
    font-size: 24px;
  }
  background-image: url(${MapImage});
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: calc(100vh - 470px);

  @media (${params.mediaQueryMaxWidth2000}) {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media only screen and (${params.mediaQueryMaxWidth1024}) {
    background-image: none;
  }

  @media only screen and (${params.mediaQueryMaxWidthLarge}) {
    white-space: normal;
    section {
      padding-top: 30px;
    }
    .contact_separator {
      visibility: hidden;
    }
  }
`;

const replaceInDom = (dom, companyData) => {
  if (!companyData) {
    return dom;
  }

  if (companyData.email) {
    dom = dom.split('[email]').join(companyData.email);
  }

  if (companyData.phone) {
    dom = dom.split('[phone]').join(companyData.phone);
  }

  if (companyData.phone_lv) {
    dom = dom.split('[phone_lv]').join(companyData.phone_lv);
  }

  return dom;
};

const getCompanyData = data => {
  if (
    !data ||
    !data.allContactsJson ||
    !data.allContactsJson.edges ||
    data.allContactsJson.edges.length === 0
  ) {
    return null;
  }

  return data.allContactsJson.edges[0].node ? data.allContactsJson.edges[0].node : null;
};

const Contacts = ({data}) => {
  const services =
    data && data.allMarkdownRemark && data.allMarkdownRemark.edges
      ? data.allMarkdownRemark.edges
          .filter(edge => edge.node.frontmatter.type === 'service')
          .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
      : [];

  const companyData = getCompanyData(data);
  return (
    <Layout>
      {services.map((service, idx) => {
        return (
          <ContactsBlock key={idx} index={idx}>
            <Hero
              content={replaceInDom(service.node.html, companyData)}
              options={{bgColor: 'none'}}
            />
          </ContactsBlock>
        );
      })}
    </Layout>
  );
};

export default Contacts;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "contacts"}}}) {
      edges {
        node {
          html
          frontmatter {
            type
            id
            order
          }
        }
      }
    }
    allContactsJson {
      edges {
        node {
          email
          phone
          phone_lv
        }
      }
    }
  }
`;
