import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../utils/theme';
import params from '../utils/params';
import {Content as PricingContent} from './responsiveElements/styledElements';

const Section = styled.section`
  width: 100%;
  height: ${props => props.height || 'auto'};
  background: ${props => (props.bgColor ? props.bgColor : theme.primaryBlue)};
  padding: ${props => props.padding || '112px 50px 50px'};

  h1 {
    margin-bottom: 24px;
  }

  p {
    max-width: 534px;
  }

  @media only screen and (${params.mediaQueryMaxWidthSmall}) {
    padding: ${props => props.padding || '59px 19px 50px'};
  }
`;

const Content = styled.div`
  max-width: 1600px;

  @media (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
    align-items: flex-start;
  }

  @media (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
  }

  margin: 0 auto;
`;

const Hero = ({content, options = {}, isPricingHero = false}) => {
  return (
    <Section className="hero_section" {...options}>
      {isPricingHero ? (
        <PricingContent className="" dangerouslySetInnerHTML={{__html: content}} />
      ) : (
        <Content className="" dangerouslySetInnerHTML={{__html: content}} />
      )}
    </Section>
  );
};

Hero.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Hero;
